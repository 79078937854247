.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layout {
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.car-image {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 100px;
}

.city-skyline {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 14px;
}

.choose-background {
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-button {
  margin: 20px 0 !important;
}

.image-option {
  border: 2px solid white;
  border-radius: 5px;
  overflow: hidden;
}

.image-option img {
  width: 100%;
  height: auto;
}

.back-button {
  margin-top: 20px !important;
}

.car-image {
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 100px;
}

.city-skyline {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

  
.mui-button {
  margin: 10px 0;
  background: #8B0000 !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid white !important;
}

.mui-button:hover {
  opacity: 0.7;
}