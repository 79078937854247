  .qr-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .image-grid-qr {
    display: grid;
    padding-top: 100px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    margin: 20px 0;
  }
  
  .image-grid-item-qr {
    aspect-ratio: 1 / 1;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    border: 3px white;
    box-shadow: 0 0 0 3px white;
  }
  
  .image-grid-item-qr img {
    width: 50%;
    height: 50%;
    object-fit: cover;
  }
