.qr-page-view  {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-grid-item-qr-view {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 15px;
}

.image-grid-item-qr-view img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.image-grid-view {
  padding-top: 10px;
}
.qr-scan {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  align-items: center;
}

.qr-scan canvas {
  width: 50px !important;
  height: 50px !important;
}

.qr-scan h1 {
  font-size: calc(1rem + 1vw);
  margin-bottom: 0px;
  margin-top: 0px;
}

.qr-h1 {
  width: 220px;
}

@media screen and (min-width: 768px) {
  .qr-h1 {
    width: 500px;
  }

  .qr-scan canvas {
    width: 150px !important;
    height: 150px !important;
  }

  .qr-scan {
      transform: translate(-50%, calc(-80%));
  }

  .image-grid-item-qr-view img {
    max-width: 70vw;
    object-fit: cover;
  }
  
}
