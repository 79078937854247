.main-page {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image-main {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* @media screen and (max-width: 767px) {
  .background-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
} */