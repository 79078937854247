body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  min-height: 100vh;
  background: radial-gradient(circle, #f0271d 10%, #480001 100%);
}

.layout {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background: radial-gradient(circle, #FF6347 10%, #8B0000 100%);
}
.layout-map {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background: radial-gradient(circle, #f0271d 10%, #480001 100%);
}

.background-image {
  position: fixed;
  top: 600px;
  left: 0;
  width: 100%;
  height: calc(100% - 600px);
  z-index: 1;
  pointer-events: none;
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Default for desktop */
}

.background-image-mux {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 20vh;
  object-fit: cover;
  object-position: top;
}

/* .background-image-mux img {
  width: 100%;
  height: 100%;
  object-fit: contain;
} */

.content {
  position: relative;
  z-index: 2;
}

.logo-container {
  display: flex;
  justify-content: center;
}

.logo-container-map{
  display: flex;
  justify-content: flex-start;
  padding: 20px 20px 10px 10px;
}

.logo {
  max-width: 200px;
  align-self: center;
  max-height: 100px;
}
.logo-map {
  max-width: 240px;
  align-self: center;
  max-height: 100px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.isuzu-text {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .background-image {
    top: 0;
    height: 100%;
  }

  .background-image {
    position: static;
  }
}

