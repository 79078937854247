.type-of-car {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.color-grid {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.color-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.grid-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.car-image-container {
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  border-radius: 8px;
  /* box-shadow: 0 0 0 3px white; */
  margin: 10px auto;
}
.car-image-container img {
  width: 100%;
  height: 100%;
}

.car-image-container .webcam {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .type-of-car {
    padding: 20px 100px;
  }

  .car-image-container {
    height: 450px;
  }

  .car-image-container img {
    width: 100%;
    height: 100%;
  }

  .grid-button {
    width: 37%;
  }

}

.background-image-type-of-car {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.car-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
}

.mui-button {
  margin: 10px 0;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.image-frame-container {
  position: relative; /* Position relative to allow absolute positioning of the canvas */
  display: inline-block; /* Ensure the container wraps around the image */
  height: 100%;
  width: 100%;
}

.image-frame {
  position: absolute; /* Position the canvas on top of the image */
  top: 0;
  left: 0;
  width: 100%; /* Match the width of the image */
  height: 100%; /* Match the height of the image */
  pointer-events: none; /* Allow clicks to pass through to the image */
}