.choose-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .image-grid-pr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    max-width: 600px;
    margin: 20px 0;
  }
  
  .image-grid-item-pr {
    position: relative;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    border: 3px white;
    box-shadow: 0 0 0 3px white;
  }
  .person-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    color: black;
    padding: 5px;
    text-align: center;
    font-weight: bold;
  }
  
  .image-grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: white;
  }
  
  .select-button, .back-button {
    margin: 10px 0;
  }

  .image-choose-person {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }