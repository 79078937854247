.vnmap-container {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 60px 20px;
  font-family: sans-serif;
  text-align: center;
}


.tooltip {
  width: 55px;
  height: 35px;
  background-color: #8B0000;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 15px rgba(129, 129, 129, 0.25));
}

.tooltip:hover {
  opacity: 0.7;
  cursor: pointer;
}

.city-name {
  background: #8B0000;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  padding: 5px;
  text-align: center;
  margin: 0;
}

.background-image-map {
  width: 380px;
  height: 330px;
  object-fit: contain;
}

.vietnam-map {
  color: #7c0a0200
}

.leaflet-container {
  margin: 0 auto;
}

.leaflet-control,
.leaflet-popup-close-button {
  display: none;
}

.leaflet-zoom-animated {
  pointer-events: visible;
}

.leaflet-map-pane {
  position: relative;
}

.leaflet-popup-pane {
  position: absolute;
  top: 0;
}

.leaflet-proxy.leaflet-zoom-animated {
  display: none;
}

.leaflet-pane.leaflet-tooltip-pane {
  position: absolute;
  top: 50px;
  z-index: 1;
}

.leaflet-popup-content {
  font-size: 12px;
}

.button-map {
  font-size: 20px !important;
  margin-top: 20px !important;
}

.vietnam-map-container { 
  position: relative;
  padding-top: 50px;
}

@media (min-width: 768px) {
  .vietnam-map-container { 
    position: relative;
    padding-top: 250px;
    transform: scale(1.6);
  }

  .background-image-map {
    width: 680px;
    height: 630px;
    object-fit: contain;
  }

  .button-map {
    font-size: 50px !important;
    margin-top: 70px !important;
  }

  .tooltip {
    width: 80px;
    height: 55px;
    background-color: #8B0000;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 15px rgba(129, 129, 129, 0.25));
  }
}